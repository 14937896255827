<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title v-if="from == 'elevator'">
        Génération d'une archive pour l'ascenseur {{ elevator.code }}
      </v-card-title>
      <v-card-title v-if="from == 'site'">
        Génération d'une archive pour le chantier
      </v-card-title>
      <v-card-text class="pt-5">
        <div class="my-3 d-flex justify-center">
          <v-progress-circular
            v-if="loading"
            :size="30"
            indeterminate
          />
          <div v-if="documentUrl">
            <a :href="documentUrl" target="_blank">
              Télécharger <v-icon class="ml-2">mdi-open-in-new</v-icon>
            </a>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="$store.state.auth.user.isAdmin"
          color="error" text
          :loading="removeLoading"
          @click="removeArchive()"
        >
          Supprimer
        </v-btn>
        <v-spacer />
        <v-btn color="grey" text @click="close">
          Annuler
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
  },
  data: () => ({
    dialog: false,
    loading: false,
    removeLoading: false,
    subject: null,
    from: '',
    elevator: null,
    site: null,
    archive: null,
    documentUrl: ''
  }),
  mounted () {

  },
  methods: {
    generate(from, item) {
      this.documentUrl = ''
      let url = ''
      if (from === 'elevator') {
        url = '/archives/elevator/' + item.id
        this.elevator = item
      }
      if (from === 'site') {
        url = '/archives/site/' + item.id
        this.site = item
      }
      this.from = from
      if (!url) {
        throw "The type of the archive entity must be an elevator or a site"
      }
      this.dialog = true
      this.loading = true
      this.$apitator.post(url, {}, { withAuth: true }).then(res => {
        const archive = res.data.data
        this.archive = archive

        if (archive.justCreated) {
          this.$store.commit('alert/add', {
            type: 'info',
            text: "Une demande de création d'archive vient d'être envoyé, veuillez patientez"
          })
        }

        if (!archive.document || !archive.document.key) {
          const url = new URL(process.env.VUE_APP_MERCURE_HUB)
          let baseTopic = process.env.VUE_APP_MERCURE_TOPIC
          //baseTopic = 'http://localhost/api'
          url.searchParams.append(
            'topic',
            baseTopic + '/archives/' + archive.id
          )
          let sse = new EventSource(url)
          sse.onmessage = ({ data }) => {
            const update = JSON.parse(data)
            console.debug('Received archive update', update)
            if (update.document && update.document.key) {
              console.debug('got key', update.document.key)
              this.documentUrl = update.document.url
              this.loading = false
              this.$forceUpdate()
              this.$store.commit('alert/add', {
                type: 'success',
                text: "L'archive a été généré !"
              })
            }
          }
        } else {
          this.documentUrl = archive.document.url
          this.loading = false
          //if (!this.$store.state.debugMode) {
            //this.close()
            //this.download(archive.document)
          //}
        }

      }).catch(err => {
        this.loading = false
        if (err.response && err.response.data.error.code === 'empty_archive') {
          this.$store.commit('alert/add', {
            type: 'warning',
            text: "Pour l'instant il n'y a rien à archiver..."
          })
          this.close()
          return
        }
        this.$store.commit('alert/add', {
          type: 'error',
          text: "La création d'archive est indisponible ou malfonctionne"
        })
      })
    },
    close() {
      this.dialog = false
    },
    download(document) {
      window.open(document.url, '_blank')
    },
    removeArchive() {
      this.removeLoading = true
      this.$store.dispatch('archives/remove', this.archive).then(() => {
        this.close()
        this.removeLoading = false
        this.$store.commit('alert/add', {
          type: 'success',
          text: "Archive supprimé"
        })
      }).catch(err => {
        console.error(err)
        this.removeLoading = false
        this.$store.commit('alert/add', {
          type: 'error',
          text: "Impossible de supprimer l'archive"
        })
      })
    }
  }
}
</script>
