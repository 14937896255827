<template>
  <v-container>
    <v-progress-linear
      v-if="loading"
      indeterminate
    />
    <v-card v-else>
      <v-card-text>
        <!-- <v-row>
          <v-col v-if="site.company" cols="12" md="6">
            <v-icon class="mr-3">mdi-domain</v-icon>
            Géré par l'entreprise
            <router-link :to="{ name: 'CompanySites', params: { id: site.company.id } }">
              "{{ site.company.name }}"
            </router-link>
          </v-col>
          <v-col v-if="site.contact" cols="12" md="6">
            <v-icon class="mr-3">mdi-account-circle</v-icon>
            <v-menu
              offset-y
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                >
                  Contact : "{{ site.contact.fullName }}"
                </span>
              </template>
              <ContactCard :user="site.contact" />
            </v-menu>
          </v-col>
        </v-row> -->
        <v-row>
          <v-col cols="12" md="6" class="d-flex align-center">
            <v-icon size="4em" class="mr-4">mdi-hammer-screwdriver</v-icon>
            <span
              class="text-h4 font-weight-regular"
              v-if="site.name"
            >
              {{ site.name }}
            </span>
          </v-col>
          <v-col cols="12" sm="6" md="3" xl="4" class="d-flex align-center">
            <div class="d-flex align-center">
              <div>
                <v-icon class="mr-3">mdi-map-marker</v-icon>
              </div>
              <div class="mr-3">
                {{ site.address.firstLine }}<br>
                <span v-if="site.address.secondLine">{{ site.address.secondLine }}<br></span>
                {{ site.address.postalCode }} {{ site.address.city }}
              </div>
            </div>
          </v-col>
          <v-col sm="6" md="3" xl="2">
            <v-btn
              block class="mb-2" color="success" text
              @click="$refs.archiveForm.generate('site', site)"
            >
              <v-icon left>mdi-archive-arrow-down</v-icon>
              {{ $vuetify.breakpoint.mdAndDown ? 'Archiver' : 'Télécharger un zip' }}
            </v-btn>
            <v-btn
              v-if="$store.state.auth.user.isAdmin"
              block class="mb-2" color="info" text
              @click="$refs.editForm.open(site)"
            >
              <v-icon left>mdi-pencil</v-icon> Modifier
            </v-btn>
            <v-btn
              v-if="$store.state.auth.user.isAdmin"
              block color="error" text
              @click="$refs.removeForm.open(site)"
            >
              <v-icon left>mdi-delete</v-icon> Supprimer
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mt-3">
      <v-tabs
        v-model="activeTab"
        exact
        centered
        fixed-tabs
      >
        <v-tab :to="{ name: 'SiteElevators' }" exact>
          Ascenseurs
        </v-tab>
        <v-tab :to="{ name: 'SiteDetails' }" exact>
          Informations
        </v-tab>
      </v-tabs>
      <router-view :site="site"></router-view>
    </v-card>
    <EditSite
      ref="editForm"
      @finished="fetchData"
    />
    <RemoveItem
      resource="sites"
      title="ce chantier"
      ref="removeForm"
      @finished="$router.push({ name: 'Sites' })"
    />
    <ArchiveForm
      ref="archiveForm"
    />
  </v-container>
</template>

<script>
import EditSite from "@/components/Sites/EditSite.vue"
import RemoveItem from "@/components/RemoveItem.vue"
import ContactCard from "@/components/ContactCard.vue"
import ArchiveForm from '@/components/ArchiveForm'

export default {
  components: {
    ArchiveForm,
    EditSite,
    RemoveItem,
    ContactCard
  },
  data: () => ({
    activeTab: '',
    site: {
    },
    loading: true
  }),
  mounted () {
    this.fetchData()
    this.refreshTab()
    if (this.$route.name == 'ViewSite') {
      this.$router.push({ name: 'SiteElevators', params: { id: this.$route.params.id } })
    }
  },
  watch: {
    '$route.path'() {
      this.refreshTab()
    }
  },
  methods: {
    refreshTab() {
      this.activeTab = this.$route.path
    },
    fetchData() {
      this.loading = true
      this.$store.dispatch('sites/fetchOne', this.$route.params.id).then(res => {
        this.loading = false
        this.site = res
      }).catch(() => {
        this.loading = false
        this.$store.commit('alert/add', {
          type: 'error',
          message: 'Impossible de charger le chantier'
        })
      })
    }
  }
}
</script>
